import React from 'react'
import styled from 'styled-components'

import HomeContent from '../components/homeContent'
import HomeFeaturedPosts from '../components/homeFeaturedPosts'
import Layout from '../components/layout'
import Seo from '../components/seo'
// import CurrentStatus from '../components/currentStatus'

import PostsList from '../styles/postsList'
import TitleWrapper from '../styles/titleWrapper'
import ContentWrapper from '../styles/contentWrapper'
import OffsetWrapper from '../styles/offsetWrapper'

const HomePage = () => {
  return (
    <>
      <Seo
        title={`Welcome!`}
      />
      <Layout>
        <ContentWrapper>
          <TitleWrapper>
            <h1>Welcome!</h1>
          </TitleWrapper>
          <OffsetWrapper>
            <HomeContent />
            <h2>
              Featured Posts
            </h2>
            <PostsList>
              <HomeFeaturedPosts />
            </PostsList>
          </OffsetWrapper>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default HomePage