import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const HomeContent = () => {

  const homeText = useStaticQuery(graphql`
    query HomeTextQuery {
      contentfulPageContent(
        slug: { eq: "home" }
      ) {
        mainContent {
          json
        }
      }
    }
  `)

  return (
    <>
    {
      documentToReactComponents(homeText.contentfulPageContent.mainContent.json)
    }
    </>
  )
}

export default HomeContent