import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useStaticQuery, graphql } from 'gatsby'

const FeaturedPosts = () => {
  const featuredPosts = useStaticQuery(graphql`
    query FeaturedPostsQuery {
      allContentfulPageContent(
        filter: {
          contentType: {
            eq: "Blog Post"
          }
          featuredPost: {
            eq: true
          }
        },
        sort: {
          fields: createdAt, 
          order: DESC
        }
        limit: 5
      ) {
        edges {
          node {
            contentful_id
            title
            slug
            excerpt {
              json
            }
          }
        }
      }
    } 
  `)

  const featuredPostList = featuredPosts.allContentfulPageContent.edges.map(({ node }) => {
    return (
      <li key={node.contentful_id}>
        <a href={`/blog/${node.slug}`}>
          <p className="post-title">{node.title}</p>
          {documentToReactComponents(node.excerpt.json)}
        </a>
      </li>
    )
  })

  return (
    <>
      {featuredPostList}
    </>
  )
}

export default FeaturedPosts


